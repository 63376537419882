$black: #233a4a;
$grey: #f3f6f6;
$green: #009698;
$white: #ffff;
$lightGrey: #c2d4d4;
$lightGreen: #e5f4f5;
$red: #ed3869;
$lightRed: #fdeaf0;
$greyish: #dae5e5;
$inputPlaceholder: #b2dfe0;
$yellow: #ffb30f;
$lightYellow: #fff7e6;
$lightGreen2: #e5f7f2;
$green2: #00ae7d;
