@use './colors.scss' as *;

html,
body {
  padding: 0;
  margin: 0;
  overflow-x: hidden;
  font-family: 'Open Sans', sans-serif;
}
*,
*::before,
*::after {
  box-sizing: border-box;
}

.center {
  width: 1440px;
  margin: 0 auto;
  position: relative;
  @media (max-width: 1440px) {
    width: 100%;
    padding: 0 15px;
    box-sizing: border-box;
  }
  @media (max-width: 375px) {
    padding: 0 7px;
  }
}

.bold_16px {
  font-weight: 700;
  font-size: 16px;
  line-height: 25px;
  color: $black;
}

.bold_14px {
  font-size: 14px;
  font-weight: 700;
  line-height: 19px;
  color: $black;
}

.regular_14px {
  font-size: 14px;
  font-weight: 400;
  line-height: 19px;
  color: $black;
}

.setsSection {
  width: 100%;
  min-height: 652px;
  height: auto;
}

.setsContent {
  padding: 31px 0 172px 0;
  min-height: 652px;
  height: auto;
  width: 759px;
  margin: 0 auto;

  @media (max-width: 1024px) {
    padding: 31px 0 172px 0;
  }
  @media (max-width: 425px) {
    padding: 30px 20px;
    width: 100%;
  }
}

.sectionTitle {
  margin: 0;
  font-size: 35px;
  line-height: 47px;
  font-weight: 700;
  color: $black;
  @media (max-width: 1024px) {
    text-align: center;
  }
  @media (max-width: 425px) {
    font-size: 24px;
  }
}

.sectionText {
  @extend .regular_14px;
  margin: 20px 0 0 0;
  line-height: 160%;
  &--payment {
    margin-bottom: 24px;
  }
  @media (max-width: 1024px) {
    text-align: center;
    max-width: 280px;
    margin: 20px auto 58px auto;
  }
}

.setsContainer {
  margin-top: 58px;
  display: flex;
  width: 100%;
  height: 280px;
  align-items: center;
  justify-content: space-between;
  @media (max-width: 1320px) {
    flex-direction: column;
    height: auto;
    gap: 20px 0;
  }
}

.setsBox {
  cursor: pointer;
  width: 272px;
  height: 100%;
  border-radius: 10px;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 60px 0 38px 0;
  background-color: $grey;
  transition: all 200ms linear;

  svg path {
    fill: $green;
  }
  svg.setBox__icon--pencil path {
    fill: $grey;
    stroke: $green;
  }

  &:hover {
    background-color: $green;

    svg path {
      fill: $white;
    }

    svg.setBox__icon--pencil path {
      fill: $green;
      stroke: $grey;
    }

    .setsBoldTitle,
    .setsBoldSubTitle {
      color: $white;
    }
  }
  @media (max-width: 425px) {
    width: 90%;
    height: auto;
    padding: 30px 0;
  }
}

.setsBoldTitle,
.setsBoldSubTitle {
  margin: 0;
  color: $black;
  font-weight: 700;
}

.setsBoldTitle {
  font-size: 25px;
  line-height: 40px;
  margin-top: 32px;
  @media (max-width: 425px) {
    font-size: 20px;
  }
}

.setsBoldSubTitle {
  font-size: 14px;
  line-height: 20px;
}

.setsIcon {
  width: 90px;
  height: 90px;
  margin-bottom: 31px;
  @media (max-width: 425px) {
    width: 60px;
    height: 60px;
  }
}

.basketListSection {
  width: 100%;
  min-height: 1226px;
  height: auto;
}

.basketListContent {
  padding: 23px 120px 166px 120px;
  min-height: 1226px;
  height: auto;
  @media (max-width: 1024px) {
    padding: 20px 20px 172px 20px;
  }
  @media (max-width: 425px) {
    padding: 20px 20px;
  }
}

.basketContent {
  width: fit-content;
  margin-top: 20px;
  display: flex;
  flex-wrap: wrap;
  gap: 20px;
  @media (max-width: 1400px) {
    justify-content: left;
    &::after {
      content: ' ';
      flex-basis: 213.8px;
    }
  }
  @media (max-width: 1200px) {
    &::after {
      flex-basis: 427.6px;
    }
  }
  @media (max-width: 1024px) {
    justify-content: center;
    &::after {
      flex-basis: 213.8px;
    }
  }
}

.basketBox {
  height: 455px;
  flex-basis: 213.8px;
  border-radius: 10px;
  box-shadow: 0px 4px 24px rgba(35, 58, 74, 0.1);
  position: relative;
}

.basketBox__helpIcon {
  @extend .bold_16px;
  position: absolute;
  cursor: pointer;
  top: 18px;
  right: 20px;
  width: 24px;
  height: 24px;
  background: $green;
  color: $white;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
}

.basketBox__image {
  width: 214px;
  height: 205px;
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;
}

.basketBox__details {
  margin: 20px auto;
  max-width: 174px;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.basketBox__title {
  @extend .bold_16px;
  margin: 0;
  text-align: center;
  max-width: 173px;
}

.basketBox__priceBox {
  margin: 16px 0;
  width: 100%;
  height: 40px;
  max-width: 173px;
  display: flex;
  justify-content: space-between;
  align-items: flex-end;
}

.basketBox__priceBrutto {
  @extend .regular_14px;
  margin: auto 0 3px 0;
  line-height: 20px;
}

.basketBox__price {
  margin: 0;
  color: $black;
  font-size: 25px;
  line-height: 40px;
  font-weight: 700;
}

.basketBox__button-disabled{
  opacity: 0.5;
  transition: all 200ms linear;
}

.basketBox__button {
  @extend .bold_14px;
  margin-bottom: 10px;
  padding: 5px 16px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  background-color: $white;
  border: 1px solid $black;
  border-radius: 7px;
  cursor: pointer;
  letter-spacing: 1px;
  &-green {
    border: 1px solid $green;
    color: $white;
    background-color: $green;
  }
  @media (max-width: 460px) {
    margin-left: unset !important;
  }
}

.configuratorSection {
  width: 100%;
  min-height: 1135px;
  height: auto;
}

.configuratorContent {
  display: flex;
  flex-wrap: wrap;
  padding: 23px 130px 0 130px;
  @media (max-width: 810px) {
    padding: 23px 0 0 0;
  }
}

.configuratorBox {
  max-width: 760px;
  width: 90%;
  @media (max-width: 1441px) {
    margin-right: 33%;
  }
  @media (max-width: 1040px) {
    margin: 0 auto;
  }
  @media (max-width: 700px) {
    max-width: 95%;
  }
}

.accordion {
  list-style: none;
  margin: 19px 0 0 0;
  padding: 0;
}

.accordionItem {
  position: relative;
}

.accordionLabel {
  @extend .bold_16px;
  padding: 20px 0;
  position: relative;
  border-top: 1px solid $lightGrey;
  display: flex;
  align-items: center;
  transition: 0.3s;
  &:hover {
    cursor: pointer;
  }

  &--active {
    .accordion_icon {
      transform: rotate(180deg);
    }
  }
}

.accordionContent {
  position: relative;
  height: 0;
  overflow: hidden;
  transition: 0.2s;
  padding-top: 0;
  padding-bottom: 0;
  width: calc(100% + 47px);
  padding-left: 20px;
  margin-left: -20px;
  padding-right: 12px;

  @media (-moz-touch-enabled: 1), (pointer:coarse) {
    width: calc(100% + 21px);
  }

  &-active {
    max-height: 472px;
    height: auto;
    overflow-y: auto;
  }
}

.stepsCustomerData {
  display: flex;
  margin: 23px 0 40px 0;
  @media (max-width: 1040px) {
    flex-direction: column;
  }
}
.stepCustomerData {
  display: flex;
  height: 24px;
  align-items: center;
  @media (max-width: 1040px) {
    margin-bottom: 10px;
  }
}

.step__text {
  font-weight: 700;
}

.steps__dividerCustomerData {
  margin: 0 26px;
  @media (max-width: 1040px) {
    display: none;
  }
}

.steps {
  display: flex;
  margin: 23px 0 40px 0;
  @media (max-width: 460px) {
    flex-direction: column;
  }
}

.step {
  display: flex;
  height: 24px;
  align-items: center;
  @media (max-width: 460px) {
    margin-bottom: 10px;
  }
}

.step__number {
  height: 24px;
  width: 25px;
  border-radius: 50%;
  margin-right: 12px;
  background-color: $green;
  color: $white;
  display: flex;
  align-items: center;
  justify-content: center;
  &-inactive {
    color: $green;
    background-color: $lightGreen;
  }
}

.steps__divider {
  margin: 0 26px;
  @media (max-width: 460px) {
    display: none;
  }
}

.accordion_icon {
  margin-left: auto;
  @media (max-width: 1040px) {
    margin-left: 15px;
  }
  &_smallmargin {
    margin-left: 16px;
  }
}

.accordionBox {
  width: calc(50% - 12px);
  display: inline-block;
  height: 220px;
  box-shadow: 0px 4px 24px rgba(35, 58, 74, 0.1);
  border-radius: 10px;
  margin-top: 12px;
  position: relative;
  vertical-align: top;
  padding-right: 20px;

  @media (max-width: 1120px) {
    width: 95%;
  }

  @media (max-width: 460px) {
    height: auto;
  }
  &--added {
    border: 1px solid #009698;
  }

  &:nth-child(odd) {
    margin-right: 24px;
  }

  &:last-child {
    margin-bottom: 12px;
  }
}

.helpIcon {
  @extend .bold_16px;
  width: 24px;
  height: 24px;
  background: $green;
  color: $white;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  position: absolute;
  top: 20px;
  right: 20px;
  cursor: pointer;
}

.itemDetails {
  display: flex;
  padding: 16px 20px 0 20px;
  @media (max-width: 460px) {
    flex-direction: column;
    align-items: center;
    padding: 16px 5px 0 5px;
  }
}

.itemImage {
  width: 120px;
  height: 120px;
  border-radius: 10px;
}

.itemData {
  display: flex;
  flex-direction: column;
  @media (max-width: 460px) {
    align-items: center;
  }
}

.itemTitle {
  @extend .bold_16px;
  max-width: 156px;
  margin: 0 0 22.5px 20px;
  @media (max-width: 460px) {
    margin: 10px 0;
    text-align: center;
  }
}

.itemPriceBox {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  height: 20px;
  margin-left: 20px;
  @media (max-width: 460px) {
    margin: 0;

    p {
      margin: 0 4px;
    }
  }
}

.item {
  @extend .regular_14px;
  margin: 0;
  &Quantity {
    font-weight: 400;
  }
  &Price {
    font-weight: 700;
  }
}

.itemControls {
  display: flex;
  @media (max-width: 460px) {
    flex-direction: column;
    align-items: center;
  }
}

.itemCounter {
  display: flex;
  width: 120px;
  height: 40px;
  align-items: center;
  justify-content: center;
  margin: 20px 0 0 20px;
  &_nomargin {
    margin: 0;
    @media (max-width: 1040px) {
      margin-bottom: 10px;
    }
  }
  @media (max-width: 460px) {
    margin: 10px 0;
    &_nomargin {
      margin: 0;
    }
  }
}

.counterIcon {
  height: 40px;
  width: 32px;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: $grey;
  cursor: pointer;
  &Minus {
    margin-right: 23.5px;
    border-top-left-radius: 10px;
    border-bottom-left-radius: 10px;

    @media (max-width: 460px) {
      margin: 10px 0;
      &--single {
        display: none;
      }
    }

  }
  &Minus_smallmargin {
    margin-right: 16.5px;
    border-top-left-radius: 10px;
    border-bottom-left-radius: 10px;
  }
  &Plus {
    margin-left: 23.5px;
    border-top-right-radius: 10px;
    border-bottom-right-radius: 10px;
  }
  &Plus_smallmargin {
    margin-left: 16.5px;
    border-top-right-radius: 10px;
    border-bottom-right-radius: 10px;
  }
}

.basketBox__button-configurator {
  width: 169px;
  height: 40px;
  margin: 19.5px 0 0 39px;
  @media (max-width: 460px) {
    display: flex;
    align-items: center;
    margin: 20px 0;
  }
}

.button {
  @extend .bold_14px;
  cursor: pointer;
  height: 40px;
  padding: 0 8px 0 20px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  border: none;
  border-radius: 7px;
  &Added {
    background-color: $lightGreen;
    color: $green;
    width: 143px;
  }
  &Delete {
    background-color: $red;
    color: $white;
    width: 123px;
    margin-left: 8px;
  }
}

.buttonAddedBox {
  margin: 19.5px 0 0 65px;

  @media (max-width: 460px) {
    margin: 20px 0;
 justify-content: center;
    display: flex;
    align-items: center;
  }
}

.itemQuantity {
  @media (max-width: 460px) {
    margin-left: 23.5px;
  }
}

.buttonsBox {
  margin: 19.5px 0 0 20px;
  display: flex;

  &--items {
    margin-bottom: 20px;
  }
}

.configuratorSummary {
  margin-top: 23px;
  width: 340px;
  min-height: 694px;
  max-height: 90vh;
  overflow: auto;
  margin-left: 51px;
  background-color: $grey;
  border-radius: 10px;
  padding: 20px;
  @media (min-width: 1024px) {
    position: fixed;
    left: 60%;
  }
  @media (max-width: 1024px) {
    position: unset;
    left: unset;
  }
  @media (max-width: 1441px) {
    margin: 23px auto 20px auto;
    left: 65%;
  }
  @media (max-width: 460px) {
    width: 90%;
    padding: 20px 5px;
    height: auto;
  }
}

.chosenSection {
  padding: 21px 0;
  border-bottom: 1px solid $lightGrey;
  border-top: 1px solid $lightGrey;
}

.summaryTitle {
  @extend .bold_16px;
  margin: 0 0 21px 0;
  letter-spacing: 0.48px;
}

.chosenItem {
  width: 300px;
  height: 64px;
  display: flex;
  align-items: center;
  background-color: $white;
  border-radius: 10px;
  margin-bottom: 12px;
  padding: 0 16px;
  @media (max-width: 460px) {
    width: 100%;
    padding: 0 4px;
  }
}

.nameInsideLabel {
  @extend .regular_14px;
  max-width: 120px;
  margin: 0 0 0 14px;
}

.chosenItemPercent {
  @extend .bold_14px;
  font-size: 16px;
  color: $green;
  margin: 0 0 0 7px;
}

.counterNum {
  transition: --num 0.5s;
  counter-reset: num var(--num);
}

.counterNum::before {
  content: counter(num);
}


.greenBasketIcon {
  margin-left: auto;
}

.warningLabel {
  width: 300px;
  height: 64px;
  display: flex;
  align-items: center;
  border-radius: 10px;
  padding: 0 16px;
  background-color: $lightRed;
  @media (max-width: 460px) {
    width: 100%;
    height: auto;
    padding: 4px;
  }
}

.warningMessage {
  @extend .regular_14px;
  color: $red;
  margin: 0 0 0 20px;

  u {
    cursor: pointer;
  }
}

.trashIcon {
  cursor: pointer;
}

.warningIcon {
  margin-bottom: 15px;
}

.summarySectionTitle {
  @extend .bold_14px;
  margin: 0 0 12px 0;
  &-resetButton {
    margin: 0;
    cursor: pointer;
  }
}

.productsSection {
  padding: 21px 0;
}

.productLabel {
  height: 38px;
  width: 300px;
  background-color: $white;
  border-radius: 10px;
  display: flex;
  align-items: center;
  padding: 0 10px;
  margin-bottom: 8px;
  @media (max-width: 460px) {
    width: 100%;
  }
}

.summaryRegularText {
  margin: 0;
  @extend .regular_14px;
  min-width: 28px;
  &_margin {
    margin: 0 16px 0 91px;
    @media (max-width: 460px) {
      margin: 0 15px 0 30px;
    }
  }
  &_margin02 {
    margin: 0 24px 0 18px;
  }
  &_wide {
    width: 148px;
  }
}

.productLabelTrashIcon {
  margin-right: 14px;
  cursor: pointer
}

.productTitles {
  display: flex;
  margin-bottom: 8px;
}

.priceSection {
  padding: 21px 0 11px 0;
  border-bottom: 1px solid $lightGrey;
  border-top: 1px solid $lightGrey;

  &--cartProject {
    margin-bottom: 20px;
    padding: 21px 0 15px;
  }
}

.priceLabel {
  height: 40px;
  display: flex;
  justify-content: space-between;
  align-items: flex-end;
}

.priceBold {
  margin: 0;
  font-weight: 700;
  font-size: 25px;
  line-height: 30px;
  color: $black;
  &-margin {
    margin-bottom: 12px;
  }
}

.basketBox__button-summary {
  width: 169px;
  margin: 0;
}

.resetButton {
  display: flex;
  width: 92px;
  height: 24px;
  cursor: pointer;
  align-items: center;
  justify-content: space-between;
  @media (max-width: 460px) {
    margin-bottom: 15px;
  }
}

.summaryButtons {
  padding: 20px 0;
  align-items: center;
  display: flex;
  justify-content: space-between;
  padding-left: 16px;
  @media (max-width: 460px) {
    flex-direction: column;
  }
}

.productInformation {
  margin-top: 23px;
  width: 340px;
  height: fit-content;
  margin-left: 51px;
  background-color: $grey;
  border-radius: 10px;
  padding: 20px;
  position: relative;
  @media (min-width: 1024px) {
    position: fixed;
    left: 60%;
  }
  @media (max-width: 1024px) {
    position: unset;
    left: unset;
  }
  @media (max-width: 1441px) {
    margin: 23px auto 20px auto;
    left: 65%;
  }
  @media (max-width: 460px) {
    width: 90%;
    padding: 20px 15px;
    height: auto;
  }
}

.closeIcon {
  position: absolute;
  top: 20px;
  right: 20px;
}

.informationBox {
  margin-top: 21px;
  padding: 20px 0;
  border-top: 1px solid $lightGrey;
}

.popupSection {
  width: 100vw;
  height: 100vh;
  position: fixed;
  z-index: 50;
  background: rgba(0, 0, 0, 0.15);
  backdrop-filter: blur(24px);
  top: 0;
}

.popup {
  width: 461px;
  height: auto;
  background-color: $white;
  border-radius: 10px;
  padding: 10px 48px 20px;
  top: 245px;
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
  @media (max-width: 510px) {
    width: 90%;
    padding: 20px;
  }
}

.popup_message {
  @extend .bold_16px;
  text-align: center;
  max-width: 365px;
  margin-bottom: 20px;
  letter-spacing: 0.48px;
  @media (max-width: 510px) {
    max-width: 90%;
    margin: 0 auto 20px auto;
  }
}

.popup_controls {
  display: flex;
  max-width: 262px;
  margin: 0 auto;
  justify-content: space-between;
  @media (max-width: 375px) {
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }
}

.popup_close {
  @extend .bold_14px;
  width: 117px;
  height: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
  border: 1px solid $black;
  background: $white;
  border-radius: 10px;
  cursor: pointer;
  letter-spacing: 1px;
  @media (max-width: 375px) {
    margin-bottom: 10px;
  }
}

.popup_confirm {
  @extend .bold_14px;
  color: $white;
  background: $green;
  border: 1px solid $green;
  height: 40px;
  width: 135px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 10px;
  cursor: pointer;
  letter-spacing: 1px;
}

.accordionPersonalizeItem {
  position: relative;
  @media (max-width: 1060px) {
    min-width: 500px;
  }
  @media (max-width: 580px) {
    min-width: 260px;
  }
}

.accordionPersonalizeContent {
  position: relative;
  height: 0;
  overflow: hidden;
  transition: 0.5s;
  display: flex;
  flex-wrap: wrap;
  &-active {
    height: 294px;
  }
  @media (max-width: 1097px) {
    flex-direction: column;
    flex-wrap: nowrap;

    &-active {
      height: fit-content !important;
      padding-bottom: 10px;
    }
  }

  @media (max-width: 500px) {
    flex-direction: column;
    flex-wrap: nowrap;

    &-active {
      height: 570px;
      padding-bottom: 10px;
    }
  }
}

.accordionPersonalizeLabel {
  @extend .bold_16px;
  padding: 20px 0;
  position: relative;
  border-top: 1px solid $lightGrey;
  display: flex;
  align-items: center;
  transition: 0.3s;
  &-active {
    .accordion_icon {
      transform: rotate(180deg);
    }
  }
  &:hover {
    cursor: pointer;
  }
  @media (max-width: 1060px) {
    flex-wrap: nowrap;
  }
}

.checkboxes {
  flex-basis: 118px;
}

.leftCol {
  flex-basis: 470px;
  display: flex;
  flex-direction: column;
  @media (max-width: 460px) {
    flex-basis: auto;
  }
}

.dragdropBox {
  width: 100%;
  height: 122px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  @media (max-width: 580px) {
    height: auto;
    flex-direction: column;
    row-gap: 20px;
  }
}

.inputBox {
  margin-top: 20px;
  width: 100%;
  height: 48px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  @media (max-width: 580px) {
    height: auto;
    flex-direction: column;
    row-gap: 20px;
  }
}

.dragDrop {
  width: 338px;
  height: 122px;
  background-color: $grey;
  border-radius: 10px;
  border: 1px dashed $green;
  padding: 24px 32px 20px 32px;
  display: flex;
  align-items: center;
  flex-direction: column;
  @media (max-width: 450px) {
    width: 250px;
  }
}

.dragDropText {
  @extend .regular_14px;
  color: $green;
  line-height: 24px;
  max-width: 228px;
  text-align: center;
}

.dragDropLink {
  text-decoration: underline;
  cursor: pointer;
}

.dragDropInput {
  width: 338px;
  height: 48px;
  position: relative;
  @media (max-width: 450px) {
    width: 250px;
  }
}

.input {
  width: 100%;
  height: 100%;
  background-color: $white;
  border: 1px solid $green;
  border-radius: 10px;
  padding-left: 16px;
  &::placeholder {
    color: $inputPlaceholder;
  }
}

.applyInput {
  @extend .bold_14px;
  position: absolute;
  right: 4px;
  top: 4px;
  width: 91px;
  height: 40px;
  border-radius: 10px;
  background-color: $green;
  color: $white;
  border: 1px solid $green;
  cursor: pointer;
  letter-spacing: 1px;
}

.imageBox {
  height: 254px;
  flex-basis: 254px;
  margin-left: auto;
  @media (max-width: 1060px) {
    margin: 20px auto 0 auto;
  }
}

.imagePersonalize {
  width: 100%;
  height: 100%;
  border-radius: 10px;
}

.checkboxContainer {
  position: relative;
}

.checkbox {
  margin: 0;
  width: 0;
  height: 0;

  &:checked {
    &:before {
      content: '\2714';
      font-size: 14px;
      color: $green;
      display: flex;
      align-items: center;
      justify-content: center;
      background-color: $inputPlaceholder;
    }
  }
  &:before {
    position: absolute;
    left: 0;
    top: 0;
    background-color: $white;
    content: '';
    display: inline-block;
    width: 24px;
    height: 24px;
    border-radius: 4px;
    border: 2px solid $inputPlaceholder;
    cursor: pointer;
  }
}

.checkboxLabel {
  display: flex;
  height: 24px;
  align-items: center;
}

.checkboxText {
  @extend .regular_14px;
  height: 24px;
  margin: 0 0 0 32px;
}

.loadingBox {
  width: 100%;
  height: 74px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  @media (max-width: 580px) {
    height: auto;
    flex-direction: column;
    row-gap: 20px;
  }
}

.loading {
  position: relative;
  width: 338px;
  height: 74px;
  background-color: $grey;
  border-radius: 10px;
  padding: 22px 32px 20px 32px;
  display: flex;
  flex-direction: column;
  @media (max-width: 580px) {
    width: 200px;
    padding: 22px 10px 20px 10px;
  }
}

.loadingText {
  @extend .regular_14px;
  margin: 0 0 10px 0;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  padding-right: 10px;

}

.loadingInput {
  position: relative;
  width: 100%;
  height: 2px;
  background-color: $inputPlaceholder;

  .fill {
    position: absolute;
    width: 30%;
    height: 2px;
    left: 0;
    top: 0;
    background-color: $green;
  }
}

.closeLoading {
  position: absolute;
  top: 20px;
  right: 20px;
  cursor: pointer;
}

.accordionBasketLabel {
  @extend .bold_16px;
  padding: 19px 37px 19px 28px;
  position: relative;
  border: 1px solid $green;
  border-radius: 10px;
  display: flex;
  align-items: center;
  transition: 0.3s;
  &-active {
    border-bottom-left-radius: 0;
    border-bottom-right-radius: 0;
    border-bottom-color: transparent;
  }
  &:hover {
    cursor: pointer;
  }
  &-active {
    .accordion_icon {
      transform: rotate(180deg);
    }
  }
  @media (max-width: 1060px) {
    flex-wrap: nowrap;
    padding: 19px 8px 19px 8px;
  }
  @media (max-width: 768px) {
    span {
      max-width: 100px;
      font-size: 14px;
    }
  }
}
.accordionBasketContent {
  position: relative;
  height: 0;
  overflow: hidden;
  transition: 0.5s;
  display: flex;
  flex-wrap: wrap;
  &-active {
    height: 347px;
    overflow-y: auto;
    border-bottom: 1px solid $green;
    border-left: 1px solid $green;
    border-right: 1px solid $green;
    border-bottom-left-radius: 10px;
    border-bottom-right-radius: 10px;
  }

  @media (max-width: 1060px) {
    flex-direction: column;
    flex-wrap: nowrap;
  }
}

.accordionBasketItem,
.accordionBasketItemShadow {
  position: relative;
  margin-bottom: 24px;
  max-width: 759px;
}

.accordionBasketItemShadow {
  &-active {
    box-shadow: 0px 4px 24px rgba(35, 58, 74, 0.1);
    border-radius: 10px;
  }
}

.accordionBasketShadowContent {
  position: relative;
  height: 0;
  overflow: hidden;
  transition: 0.5s;
  display: flex;
  flex-wrap: wrap;
  flex-direction: column;
  margin: 0;
  list-style: none;
  &-active {
    height: 347px;
    overflow-y: auto;
    @media (max-width: 550px) {
      height: 457px;
    }
  }
}

.accordionBasketLabelShadow {
  @extend .bold_16px;
  padding: 19px 37px 19px 28px;
  position: relative;
  border-radius: 10px;
  display: flex;
  align-items: center;
  transition: 0.3s;
  box-shadow: 0px 4px 24px rgba(35, 58, 74, 0.1);
  border: 1px solid transparent;
  &-active {
    border-bottom-left-radius: 0;
    border-bottom-right-radius: 0;
    box-shadow: unset;
  }

  &-active {
    .accordion_icon {
      transform: rotate(180deg);
    }
  }
  &:hover {
    cursor: pointer;
  }
  @media (max-width: 1060px) {
    flex-wrap: nowrap;
    padding: 19px 8px 19px 8px;
  }
  @media (max-width: 768px) {
    span {
      max-width: 100px;
      font-size: 14px;
    }
  }
}

.basketSummary {
  width: 340px;
  height: 291px;
  margin-left: 51px;
  background-color: $grey;
  border-radius: 10px;
  padding: 20px;

  &--cartProject {
    margin-top: 23px;
  }

  @media (max-width: 1441px) {
    margin: 23px auto 20px auto;
  }
  @media (max-width: 460px) {
    width: 90%;
    padding: 20px 5px;
    height: auto;
  }
}

.basketSummaryButton {
  @extend .bold_14px;
  margin-top: 8px;
  padding: 5px 16px;
  height: 40px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  background-color: $green;
  color: $white;
  border: 1px solid $green;
  border-radius: 7px;
  cursor: pointer;
  letter-spacing: 1px;
  &_nomargin {
    margin: 0;
  }
}

.basketLabelRegular {
  @extend .regular_14px;
  margin: 0 5px 0 20px;
  @media (max-width: 1040px) {
    margin: 0 0 10px 0;
  }
}

.basketLabelBold {
  @extend .bold_16px;
  margin: 0;
  @media (max-width: 1040px) {
    margin: 0 0 10px 0;
    text-align: center;
    font-size: 14px;
  }
}

.squareBtn {
  width: 40px;
  height: 40px;
  padding: 8px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  border-radius: 7px;
  border: none;
  margin-left: 8px;
  &_green {
    background-color: $lightGreen;
  }
  &_red {
    background-color: $red;
  }
  @media (max-width: 1040px) {
    margin: 0;
  }
}

.basketLabelDetails {
  display: flex;
  height: 40px;
  align-items: center;
  margin-left: 175px;
  @media (max-width: 1040px) {
    margin-left: auto;
    height: auto;
    flex-direction: column;
  }
}

.basketLabelControls {
  display: flex;
  height: 40px;
  align-items: center;
  margin-left: 96px;
  @media (max-width: 1040px) {
    height: auto;
    margin-left: auto;
    flex-direction: column;
  }
}

.rectangleBtn {
  @extend .bold_14px;
  color: $white;
  width: 77px;
  height: 40px;
  padding: 8px 16px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  border-radius: 7px;
  border: none;
  background-color: $green;
  margin-left: 8px;
  letter-spacing: 1px;
  @media (max-width: 1040px) {
    margin: 0;
  }
}

.basketShadowList {
  padding: 12px 28px 0 20px;
  width: 100%;
  height: 281px;
  overflow-y: auto;
  margin: 0;
  li:last-child {
    border: none;
  }

  @media (max-width: 700px) {
    &.noOverflow {
      overflow-y: auto;
    }
  }
}

.basketItem {
  min-height: 89px;
  height: auto;
  width: 100%;
  padding: 12px 8px;
  border-bottom: 1px solid $lightGrey;
  display: flex;
  align-items: center;
  flex-wrap: wrap;
}

.basketItem_image {
  width: 65px;
  height: 65px;
  margin-right: 20px;
  @media (max-width: 700px) {
    margin: 0 20px 15px 0;
  }
}

.basketItem_bold {
  margin: 0;
  @extend .bold_16px;
  @media (max-width: 768px) {
    margin: 0;
  }
  @media (max-width: 550px) {
    max-width: 60px;
  }
}

.basketItem_regular {
  margin: 0;
  @extend .regular_14px;
}

.basketItem_detailsContainer {
  width: 306px;
  display: flex;
  justify-content: space-between;
  margin-left: auto;
}

.basketItem_details {
  display: flex;
  flex-direction: column;
  row-gap: 5px;
  width: 62px;
  align-items: center;
}

.basketItem_controls {
  display: flex;
  height: 40px;
  width: 391px;
  justify-content: space-between;
  margin: 0 28px 0 auto;
  @media (max-width: 550px) {
    flex-direction: column;
    width: 117px;
    margin: 10px auto 0 auto;
  }
}

.basket_controlBtn {
  @extend .bold_14px;
  color: $black;
  width: 117px;
  height: 40px;
  cursor: pointer;
  background-color: $white;
  border: 1px solid $black;
  border-radius: 7px;
  padding: 8px 16px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  @media (max-width: 550px) {
    margin-bottom: 10px;
  }
}

.basketSectionContent {
  display: flex;
  flex-wrap: wrap;
  padding: 23px 130px 0 130px;
  @media (max-width: 1070px) {
    padding: 23px 5px;
  }
  @media (max-width: 1040px) {
    flex-direction: column;
  }
}

.customerDataSummary {
  width: 340px;
  height: 195px;
  margin-left: 51px;
  background-color: $grey;
  border-radius: 10px;
  padding: 20px;
  @media (max-width: 1441px) {
    margin: 23px auto 20px auto;
  }
  @media (max-width: 460px) {
    width: 90%;
    padding: 20px 5px;
    height: auto;
  }
}

.customerDataButtons {
  padding-top: 20px;
  border-top: 1px solid $lightGrey;
}

.backToBasketBtn {
  @extend .bold_14px;
  width: 300px;
  height: 40px;
  color: $black;
  border: 1px solid $black;
  padding: 8px 16px;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: space-between;
  border-radius: 7px;
  letter-spacing: 1px;
  @media (max-width: 460px) {
    width: 100%;
  }
  &_shorter {
    width: 293px;
    margin: 24px 0;
    background-color: $white;
    @media (max-width: 460px) {
      width: 90%;
      margin: 24px auto;
    }
  }
}

.customerDataContainer {
  display: flex;
  flex-direction: column;
  margin-top: 24px;
  border-bottom: 1px solid $lightGrey;
}
.customerDataBox {
  height: auto;
  padding: 20px 0 24px 0;
  border-top: 1px solid $lightGrey;
}

.customerDataInputs {
  display: flex;
  flex-wrap: wrap;
  gap: 20px;
  @media (max-width: 1150px) {
    justify-content: center;
  }
}

.customerDatainputBox {
  width: 370px;
  height: 72px;
  display: flex;
  flex-direction: column;
  position: relative;
}

.customerDataLabel {
  @extend .regular_14px;
  margin: 0 0 4px 0;
}

.customerDataInput {
  @extend .regular_14px;
  width: 100%;
  height: 48px;
  padding: 14px 16px;
  border-radius: 10px;
  border: 1px solid $green;

  &::placeholder {
    @extend .regular_14px;
  }

  &_warn {
    background-color: $lightYellow;
    border: 1px solid $yellow;
    padding-right: 35px;
    &::placeholder {
      color: $yellow;
    }
  }

  &_error {
    background-color: $lightRed;
    border: 1px solid $red;
    padding-right: 35px;
    &::placeholder {
      color: $red;
    }
  }

  &_agree {
    background-color: $lightGreen2;
    border: 1px solid $green2;
    padding-right: 35px;
    &::placeholder {
      color: $green2;
    }
  }
}

.inputIcon {
  position: absolute;
  bottom: 12px;
  right: 12px;
}

.historySummary {
  width: 340px;
  height: 147px;
  margin-left: 51px;
  background-color: $grey;
  border-radius: 10px;
  padding: 20px;
  @media (max-width: 1441px) {
    margin: 23px auto 20px auto;
  }
  @media (max-width: 460px) {
    width: 90%;
    padding: 20px 5px;
    height: auto;
  }
}

.historyLabelData {
  display: flex;
  height: 40px;
  align-items: center;
  margin-left: auto;
  @media (max-width: 1040px) {
    height: auto;
    flex-direction: column;
  }
}

.placedOrderContainer {
  display: flex;
  flex-wrap: wrap;
  padding: 23px 335px 0 335px;
  @media (max-width: 1450px) {
    padding: 23px 50px 0 50px;
    justify-content: center;
  }
  @media (max-width: 903px) {
    flex-direction: column;
    align-items: center;
    padding: 23px 20px 0 20px;
  }
}

.placedOrderText {
  @extend .regular_14px;
  margin: 20px 0 0 0;
  line-height: 160%;
  max-width: 465px;
}

.placedOrderTitle {
  margin: 0;
  font-size: 35px;
  line-height: 47px;
  font-weight: 700;
  color: $black;
  @media (max-width: 425px) {
    font-size: 24px;
  }
}

.payBtn {
  @extend .bold_14px;
  height: 40px;
  width: 354px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  cursor: pointer;
  letter-spacing: 1px;
  color: $white;
  background-color: $green;
  border: 1px solid $green;
  padding: 8px 16px;
  border-radius: 7px;
  margin-top: 24px;
  @media (max-width: 425px) {
    max-width: 90%;
    height: auto;
  }
}

.checkmarkBox {
  width: 174px;
  height: 174px;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: $lightGreen;
  border-radius: 9px;
  margin-left: 119px;
  @media (max-width: 903px) {
    margin: 20px auto;
  }
}

.placedOrderMessage {
  max-width: 485px;
  @media (max-width: 903px) {
    display: flex;
    align-items: center;
    text-align: center;
    flex-direction: column;
  }
  @media (max-width: 425px) {
    max-width: 90%;
  }
}

.choosePaymentSection {
  width: 100%;
  padding-top: 24px;
  border-top: 1px solid $lightGrey;
  display: flex;
  flex-wrap: wrap;
  gap: 10px;
  margin-bottom: 20px;
  justify-content: center;
  &::after {
    content: '';
    flex-basis: 246px;
  }
}

.choosePaymentBox {
  width: 246px;
  height: 176px;
  border: 1px solid $green;
  border-radius: 10px;
  padding: 16px 24px 24px 24px;
  display: flex;
  flex-direction: column;
  position: relative;
  cursor: pointer;
}

.choosePaymentText {
  @extend .bold_14px;
  text-align: center;
  margin: 0;
}

.choosePaymentImage {
  margin: 20px 0 0 44px;
  width: 154px;
  height: 96px;
}

.choosePaymentInput {
  width: 24px;
  height: 24px;
  position: absolute;
  left: 24px;
  top: 92px;
  cursor: pointer;

  &::after {
    width: 24px;
    height: 24px;
    border: 2px solid $inputPlaceholder;
    position: relative;
    content: '';
    display: inline-block;
    border-radius: 50%;
  }

  &:checked::after {
    border: 5px solid $inputPlaceholder;
    background-color: $green;
  }
}

.noOverflow {
  overflow-y: hidden;
}

.priceCartProjectLabel {
  line-height: 15px;
}

.resetAll {
  all: revert;
}

.button_disabled{
  cursor: not-allowed;
  opacity: 0.5;
}